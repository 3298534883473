import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({
  component: Component,
  location,
  ...props
}) {
  const token = localStorage.getItem('access_token');

  return (
    <Route
      {...props}
      render={(innerProps) => {
        if (!token)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location?.pathname },
              }}
            />
          );
        return <Component {...innerProps} />;
      }}
    />
  );
}
