import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';

import { Link } from 'react-router-dom';

import { useCourses } from '../../hooks/user';

export default function Courses() {
  const { data: courses } = useCourses();

  return (
    <>
      <Container maxW="container.lg">
        <Heading my={5} style={{ display: 'flex', alignItems: 'center' }}>
          Courses
        </Heading>
      </Container>

      <Container maxW="container.lg" centerContent>
        <SimpleGrid columns={[1, null, 3]} spacing={5} w="100%">
          {courses &&
            courses.map((course) => (
              <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                bg="white"
                key={course.id}
                as={Link}
                to={`/dashboard/courses/${course.id}`}
              >
                {/* <Text mb={4}>
                {event.date} {event.time}
              </Text> */}
                <Heading size="md">{course.title}</Heading>
                <Text mt={4}>{course.description}</Text>
              </Box>
            ))}
        </SimpleGrid>
      </Container>
    </>
  );
}
