import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  FormHelperText,
} from '@chakra-ui/react';
import { useField } from 'formik';

export default function InputField({
  label,
  helperText,
  stack = 'row',
  options,
  ...props
}) {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        {...props}
        {...field}
        onChange={(val) => helpers.setValue(val)}
      >
        <Stack direction={stack}>
          {options.map((o, k) => (
            <Radio
              key={k}
              id={`${field.name}-id-${k}`}
              isChecked={field.value === o.value}
              value={o.value}
            >
              {o.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
