import apiClient from '../app/apiClient';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const getMatches = async () => {
  const { data } = await apiClient.get('career-matches');
  return data;
};

export function useCareerMatches() {
  return useQuery('careerMatches', getMatches, {
    initialData: [],
    refetchOnWindowFocus: false,
  });
}

const getCareers = async () => {
  const { data } = await apiClient.get('careers');
  return data;
};

const getCareer = async (uuid) => {
  const { data } = await apiClient.get(`careers/${uuid}`);
  return data;
};

const getCareerStates = async (uuid) => {
  const { data } = await apiClient.get(`careers/states`);
  return data;
};

const getCompany = async (uuid) => {
  const { data } = await apiClient.get(`companies/${uuid}`);
  return data;
};

const getCareerByFormId = async (formId) => {
  const { data } = await apiClient.get(`career/form/${formId}`);
  return data;
};

const getMatch = async (uuid) => {
  const { data } = await apiClient.get(`career/${uuid}/match`);
  return data;
};

const getOtherCareers = async (uuid) => {
  const { data } = await apiClient.get(`career/${uuid}/other-careers`);
  return data;
};

const getSkillbridge = async (id) => {
  const { data } = await apiClient.get(`skillbridge/${id}`);
  return data;
};

export const getCareerPublic = async (source) => {
  const { data } = await apiClient.get(`career/${source}/public`);
  return data;
};

const requestInterview = async (uuid, accept = true) => {
  console.log(accept);
  const { data } = await apiClient.post(`career/${uuid}/interview`, { accept });
  return data;
};

const requestVideoAskInterview = async ({ uuid, accept = true }) => {
  console.log(uuid, accept);
  const { data } = await apiClient.post(`career/${uuid}/interview`, { accept });
  return data;
};

const acceptOrDeclineInterview = async ({ uuid, accept = true }) => {
  const { data } = await apiClient.post(
    `candidates/${uuid}/${accept ? 'accept' : 'decline'}`
  );
  return data;
};

export const getCandidateSummary = async (uuid, pin) => {
  const { data } = await apiClient.post(`candidates/${uuid}`, { pin });
  return data;
};

export function useCareers() {
  return useQuery('careers', getCareers, {
    initialData: [],
    refetchOnWindowFocus: false,
  });
}

export function useCareer(uuid) {
  return useQuery(['career', uuid], () => getCareer(uuid), {
    cacheTime: 0,
    initialData: null,
    enabled: !!uuid,
    refetchOnWindowFocus: false,
  });
}

export function useCareerStates() {
  return useQuery('careerStates', () => getCareerStates(), {
    initialData: null,
    refetchOnWindowFocus: true,
  });
}

export function useCompany(uuid) {
  return useQuery(['company', uuid], () => getCompany(uuid), {
    cacheTime: 0,
    initialData: null,
    enabled: !!uuid,
    refetchOnWindowFocus: false,
  });
}

export function useCareerWithFormId(formId) {
  return useQuery(['career', formId], () => getCareerByFormId(formId), {
    cacheTime: 0,
    initialData: null,
    enabled: !!formId,
    refetchOnWindowFocus: false,
  });
}

export function useOtherCareers(uuid) {
  return useQuery('otherCareers', () => getOtherCareers(uuid), {
    cacheTime: 0,
    initialData: null,
    enabled: !!uuid,
    refetchOnWindowFocus: false,
  });
}

export function useCareerMatch(uuid) {
  return useQuery('careerMatch', () => getMatch(uuid), {
    initialData: null,
    enabled: !!uuid,
    refetchOnWindowFocus: false,
  });
}

export function useSkillBridge(id) {
  return useQuery('skillbridge', () => getSkillbridge(id), {
    cacheTime: 0,
    initialData: null,
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
}

export function useVideoAskInterview() {
  const queryClient = useQueryClient();
  return useMutation(requestVideoAskInterview, {
    onSuccess: async (data) => {
      queryClient.setQueryData('careerMatch', data);
      const previousData = queryClient.getQueryData('careerMatches');

      if (previousData && previousData.length > 0) {
        queryClient.setQueryData('careerMatches', [...previousData, data]);
      } else {
        queryClient.setQueryData('careerMatches', [data]);
      }
    },
  });
}

export function useRequestInterview() {
  const queryClient = useQueryClient();
  return useMutation(requestInterview, {
    onSuccess: async (data) => {
      queryClient.setQueryData('careerMatch', data);
      const previousData = queryClient.getQueryData('careerMatches');

      if (previousData && previousData.length > 0) {
        queryClient.setQueryData('careerMatches', [...previousData, data]);
      } else {
        queryClient.setQueryData('careerMatches', [data]);
      }
    },
  });
}

export function useAcceptInterview() {
  const queryClient = useQueryClient();
  return useMutation(acceptOrDeclineInterview, {
    onSuccess: async (data) => {
      queryClient.setQueryData('candidate', data);
    },
  });
}
