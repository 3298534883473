import React, { useMemo } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { useField } from 'formik';
import Select from 'react-select';

export default function SelectField({
  label,
  labelKey,
  valueKey,
  helperText,
  placeholder = 'Select...',
  isMulti = false,
  options,
  ...props
}) {
  const [field, meta, helpers] = useField(props);

  const fieldValue = useMemo(() => {
    if (!options || !field.value) return '';
    if (isMulti) {
      return options.filter((option) =>
        field.value.some(
          (v) => option[valueKey || 'id'] === v[valueKey || 'id']
        )
      );
    } else {
      return options.find((option) => option[valueKey || 'id'] === field.value);
    }
  }, [options, field.value, isMulti, valueKey]);

  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      <FormLabel>{label}</FormLabel>
      <Select
        name={field.name}
        placeholder={placeholder}
        options={options}
        value={fieldValue}
        isMulti={isMulti}
        isClearable
        onBlur={(evt) => {
          helpers.setTouched(true);
          field.onBlur(evt);
        }}
        onChange={(option) =>
          helpers.setValue(
            option ? (isMulti ? option : option[valueKey || 'id']) : ''
          )
        }
        getOptionLabel={(option) => option[labelKey || 'value']}
        getOptionValue={(option) => option[valueKey || 'id']}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
