import React from "react";
import { Center, Grid, Spinner as ChakraSpinner } from "@chakra-ui/react";

export default function Spinner() {
  return (
    <Grid h="100vh">
      <Center h="100%" w="100%">
        <ChakraSpinner size="xl" color="brand.secondary" />
      </Center>
    </Grid>
  );
}
