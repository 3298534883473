import React, { useMemo } from 'react';

import { Box, Flex, Spacer } from '@chakra-ui/react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { useUser } from '../../hooks/user';
import Navigation from './Navigation';
import Events from './Events';
import BasicProfileComplete from '../user/BasicProfileComplete';
import Schedule from './Schedule';
import Chat from './Chat';
import Courses from './Courses';
import Modules from './Modules';
import CareerMatches from '../opportunities/CareerMatches';
import Career from '../opportunities/Career';
import Podcast from './Podcast';

const Routes = [
  {
    url: '',
    component: Events,
    exact: true,
    status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  },
  {
    url: '/bonus-program',
    component: (props) => (
      <Box pt={8}>
        <BasicProfileComplete {...props} />
      </Box>
    ),
    exact: true,
    status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  },
  {
    url: '/chat',
    component: Chat,
    exact: true,
    status: ['showcase', 'premium'],
  },
  {
    url: '/courses',
    component: Courses,
    exact: true,
    status: ['learn', 'showcase', 'basic', 'premium'],
  },
  // {
  //   url: '/jobs',
  //   component: CareerMatches,
  //   exact: true,
  //   status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  // },
  // {
  //   url: '/jobs/:uuid',
  //   component: Career,
  //   exact: true,
  //   status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  // },
  {
    url: '/courses/:courseId',
    component: Modules,
    exact: true,
    status: ['learn', 'showcase', 'basic', 'premium'],
  },
  {
    url: '/schedule',
    component: Schedule,
    exact: true,
    status: ['showcase', 'basic', 'premium'],
  },
  {
    url: '/listen',
    component: Podcast,
    exact: true,
    status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  },
];

export default function Dashboard() {
  const { data: user = {} } = useUser();
  let { path } = useRouteMatch();

  const filteredRoutes = useMemo(() => {
    return Routes.filter((r) => r.status.includes(user?.profile?.user_status));
  }, [user]);

  return (
    <>
      <Flex>
        <Spacer /> <Navigation />
      </Flex>
      <Switch>
        {filteredRoutes.map((r, k) => (
          <Route
            exact={r.exact}
            path={path + r.url}
            component={r.component}
            key={r.url}
          />
        ))}
      </Switch>
    </>
  );
}
