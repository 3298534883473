import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';

export default function InputField({ label, helperText, ...props }) {
  const [field, meta] = useField(props);
  const { submitCount } = useFormikContext();

  return (
    <FormControl isInvalid={meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel>{label}</FormLabel>
      <Input {...field} {...props} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
