import React from 'react';
import {
  Button,
  Flex,
  Link,
  Spacer,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import CardContainer from '../layout/CardContainer';
import { useUser } from '../../hooks/user';

export default function ProfileComplete() {
  const { data: user } = useUser();

  const { hasCopied, onCopy } = useClipboard(
    `https://${window.location.hostname}/${user.profile.sharing_url_segment}/verify`
  );

  const heading = !user?.profile?.email_confirmed
    ? 'Please Confirm Your Email'
    : 'Thank You!';

  return (
    <CardContainer heading={heading}>
      {!user?.profile?.email_confirmed && (
        <>
          <Text pb={2} pl={2}>
            Thanks {user.first_name} for submitting your registration. The next
            step is to confirm your email address. We just emailed you at{' '}
            {user.email}. Please check your inbox and confirm your email
            address.
          </Text>
          <Text pb={2} pl={2}>
            Thanks!
          </Text>
        </>
      )}

      {user?.profile?.email_confirmed && (
        <>
          <Text pb={2} pl={2}>
            Your email has been confirmed. Thank you for registering with us!
          </Text>
        </>
      )}
    </CardContainer>
  );
}
