import { useEffect } from 'react';
import { Container } from '@chakra-ui/react';

import '@sendbird/uikit-react/dist/index.css';

import { useUser } from '../../hooks/user';
import Spinner from '../layout/Spinner';
import SendbirdApp from '@sendbird/uikit-react/App';

import './theme.css';

export default function Chat() {
  const { data: user = {}, refetch } = useUser();

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log('Refetching user data...');
      refetch(); // Call the refetch function from useUser hook
    }, 1805000); // 1805000 ms is exactly 30 minutes and 5 seconds for race condition

    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, [refetch]);

  if (!user || !user.sendbird_account_uuid) {
    return (
      <Container maxW="container.xl" centerContent>
        <Spinner />
      </Container>
    );
  }

  return (
    <SendbirdApp
      appId="1B1D6000-ACB3-4D44-BFF0-B0EA58D4E0E4"
      userId={user.sendbird_account_uuid}
      breakpoint="567px"
    />
  );
}
