import * as yup from 'yup';

// const numberReg = /^\d+$/;
// const numberRegMessage = 'Can only contain numbers';

export const loginSchema = yup.object().shape({
  username: yup.string().email().required('Required'),
  password: yup.string().required('Required'),
});

export const registerSchema = yup.object().shape({
  username: yup.string().email().required(),
  password: yup.string().min(8, 'Password must be 8 characters.').required(),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
});

export const basicProfileSchema = yup.object().shape({
  active_security_clearance: yup
    .boolean()
    .label('Security clearance')
    .required(),
  branch_of_service: yup.string().label('Branch of Service').required(),
  current_location: yup.string().label('Current Location').required(),
  preferred_location: yup.string().label('Preferred Location').required(),
  email: yup.string().email().label('Email').required(),
  email_confirm: yup
    .string()
    .email()
    .label('Confirm Email')
    .required()
    .oneOf([yup.ref('email'), null], 'Emails must match'),
  ethnicities: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        id: yup.string(),
      })
    )
    .min(1, 'Ethnicity: Please select at least one ethnicity')
    .label('Ethnicity')
    .required(),
  gender: yup.string().label('Gender').required(),
  sms_opt_in: yup.string().label('SMS Opt In').required(),
  gender_other: yup
    .string()
    .label('Gender Other')
    .when('gender', {
      is: 'other',
      then: yup.string().required('Please specify your gender'),
    }),
  phone: yup
    .string()
    .label('Phone')
    .matches(new RegExp(/[0-9]{11}/), 'Invalid phone format')
    .required(),
  pay_grade: yup.number().label('Pay Grade').required().positive().integer(),
  education_level: yup.number().label('Degree').required().positive().integer(),
  military_occupation: yup.string().label('Military Occupation').required(),
  military_occupation_desc: yup
    .string()
    .label('Military Occupation Description')
    .required(),
  service_status: yup
    .string()
    .label('"As of today, I am:" question')
    .required(),
  preferred_communication_type: yup
    .string()
    .label('Communication Method')
    .required(),
  security_clearance_level: yup
    .number()
    .label('Security clearance level')
    .required(),
  separation_date: yup
    .date()
    .typeError('Please enter a date')
    .label('EAS/EAOS Date')
    .required(),
  total_years_of_service: yup
    .number()
    .typeError('Please enter a number')
    .label('Total Years of Service')
    .required(),
});

export const getBasicProfileValidationSchema = (serviceStatus) => {
  const baseSchema = {
    active_security_clearance: yup
      .boolean()
      .label('Security clearance')
      .required(),
    branch_of_service: yup.string().label('Branch of Service').required(),
    current_location: yup.string().label('Current Location').required(),
    date_available: yup
      .date()
      .required('Date available for full-time employment is required'),
    preferred_location: yup.string().label('Preferred Location').required(),
    email: yup.string().email().label('Email').required(),
    email_confirm: yup
      .string()
      .email()
      .label('Confirm Email')
      .required()
      .oneOf([yup.ref('email'), null], 'Emails must match'),
    ethnicities: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string(),
          id: yup.string(),
        })
      )
      .min(1, 'Ethnicity: Please select at least one ethnicity')
      .label('Ethnicity')
      .required(),
    gender: yup.string().label('Gender').required(),
    sms_opt_in: yup.string().label('SMS Opt In').required(),
    gender_other: yup
      .string()
      .label('Gender Other')
      .when('gender', {
        is: 'other',
        then: yup.string().required('Please specify your gender'),
      }),
    phone: yup
      .string()
      .label('Phone')
      .matches(new RegExp(/[0-9]{11}/), 'Invalid phone format')
      .required(),
    pay_grade: yup.number().label('Pay Grade').required().positive().integer(),
    education_level: yup
      .number()
      .label('Degree')
      .required()
      .positive()
      .integer(),
    military_occupation: yup.string().label('Military Occupation').required(),
    military_occupation_desc: yup
      .string()
      .label('Military Occupation Description')
      .required(),
    service_status: yup
      .string()
      .label('"As of today, I am:" question')
      .required(),
    preferred_communication_type: yup
      .string()
      .label('Communication Method')
      .required(),
    security_clearance_level: yup
      .number()
      .label('Security clearance level')
      .required(),
    total_years_of_service: yup
      .number()
      .typeError('Please enter a number')
      .label('Total Years of Service')
      .required(),
  };

  console.log(serviceStatus);

  if (serviceStatus === 'active') {
    return yup.object().shape({
      ...baseSchema,
      separation_date: yup.date().required('Transition date is required'),
      job_title: yup.string().label('Job Title or Career Path').required(),
    });
  } else if (serviceStatus === 'veteran') {
    return yup.object().shape({
      ...baseSchema,
      // veteran specific validations
      separation_date: yup.date().required('Transition date is required'),
      job_title: yup.string().label('Current Job Title').required(),
    });
  } else if (serviceStatus === 'national-guard') {
    return yup.object().shape({
      ...baseSchema,
      job_title: yup
        .string()
        .label('Current or Most Recent Civilian Job Title')
        .required(),
    });
  } else {
    return yup.object().shape({
      ...baseSchema,
    });
  }
};
