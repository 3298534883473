import { useEffect, useMemo } from 'react';
import { useUser } from '../../hooks/user';
import './book-button.css';

export default function BookButton({ handleEventScheduled = () => {} }) {
  const { data: user, isLoading: loadingUser } = useUser();

  const userParams = useMemo(() => {
    if (!user) {
      return null;
    }

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const formattedCurrentMonth =
      currentMonth < 10 ? `0${currentMonth}` : currentMonth;
    const formattedCurrentYear = `${currentYear}`;
    const formattedCurrentDate = `${formattedCurrentYear}-${formattedCurrentMonth}`;

    return `month=${formattedCurrentDate}&name=${user.first_name} ${user.last_name}&email=${user.email}`;
  }, [user]);

  useEffect(() => {
    // Function to determine if the event is a Calendly event
    function isCalendlyEvent(e) {
      return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    // Function to handle the message event
    function handleMessage(e) {
      if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
        handleEventScheduled();
      }
    }

    // Add the event listener
    window.addEventListener('message', handleMessage);

    // Clean up the event listener
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleEventScheduled, userParams]);

  if (
    !user ||
    loadingUser ||
    !user.profile ||
    user.profile.user_status !== 'premium'
  ) {
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    window.Calendly.initPopupWidget({
      url: `https://calendly.com/transition-overwatch-support?${userParams}`,
      // url: `https://calendly.com/allen-8uxl/30min?${userParams}`,
    });
    return false;
  };

  return (
    <>
      <a
        href="/#"
        className="calendly-embed-button"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        Schedule Appointment
      </a>
    </>
  );
}
