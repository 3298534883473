import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import Header from './Header';
import useRouteUser from '../../hooks/routing';
import { useUser } from '../../hooks/user';
import Spinner from '../layout/Spinner';
import { useIntercom } from 'react-use-intercom';

export default function InternalLayout(props) {
  useRouteUser();
  const { data: user } = useUser();
  const { boot } = useIntercom();
  const [active, setActive] = useState(false);

  const profileToIntercom = (user) => {
    const branches = {
      army: 'United States Army',
      'air-force': 'United States Air Force',
      'coast-guard': 'United States Coast Guard',
      'marine-corps': 'United States Marine Corps',
      navy: 'United States Navy',
      'space-force': 'United States Space Force',
    };

    return {
      branch: branches[user?.profile?.branch_of_service],
      eas: user?.profile?.separation_date,
      email_confirmed: user?.profile?.email_confirmed,
      communication_method: user?.profile?.preferred_communication_type,
      greenhouse_profile: user?.profile?.greenhouse_profile_url,
      pay_grade: user?.profile?.pay_grade?.name,
      profile_link: user?.profile?.profile_link,
      source: user?.profile?.registration_tracking_name,
      status: user?.profile?.is_veteran ? 'Veteran' : 'Active',
    };
  };

  if (user && user?.profile?.email_confirmed && !active) {
    setActive(true);

    const customAttributes = profileToIntercom(user);
    boot({
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      phone: user.profile.phone,
      customAttributes,
    });
  }

  return (
    <Box h="100vh" minW="100%" display="flex" flexDirection="column">
      <Header />
      <Box flex="1" overflow="hidden" minW="100%" bgColor="gray.100">
        {!user && <Spinner />}
        {user && props.children}
      </Box>
    </Box>
  );
}
