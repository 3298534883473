import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import {
  AlertIcon,
  Alert,
  AlertDescription,
  AlertTitle,
  CloseButton,
} from "@chakra-ui/react";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>Oops! Something went wrong.</AlertTitle>
      <AlertDescription>{error.message}</AlertDescription>
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={resetErrorBoundary}
      />
    </Alert>
  );
}

export default function ErrorBoundaryInternal({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}
