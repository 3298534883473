import { useEffect, useCallback, useMemo } from 'react';

import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { useParams, useLocation } from 'react-router-dom';
import { useCourse, useUser } from '../../hooks/user';

const VideoAskWidget = ({ module, open, user }) => {
  const { id, videoask_url, description, title } = module;

  const openModal = useCallback(() => {
    console.log(
      `${videoask_url}?contact_email=${encodeURIComponent(
        user.email
      )}&first_name=${encodeURIComponent(
        user.first_name
      )}&last_name=${encodeURIComponent(
        user.last_name
      )}&branch=${encodeURIComponent(user?.profile?.branch_of_service)}`
    );
    window.videoask.loadModal({
      url: `${videoask_url}?contact_email=${encodeURIComponent(
        user.email
      )}&first_name=${encodeURIComponent(
        user.first_name
      )}&last_name=${encodeURIComponent(
        user.last_name
      )}&branch=${encodeURIComponent(user?.profile?.branch_of_service)}`,
      options: {
        // text: "Let's get started",
        backgroundColor: '#150E32',
        dismissable: false,
        // position: 'bottom-right',
        position: 'inline',
        widgetTarget: `#va-form-${id}`,
        modalType: 'fullScreen',
      },
    });
  }, [videoask_url, id, user]);

  useEffect(() => {
    if (open) {
      openModal();
    }
  }, [open, openModal]);

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      bg="white"
      onClick={openModal}
      cursor={'pointer'}
    >
      <Heading size="md">{title}</Heading>
      <Text mt={4}>{description}</Text>
    </Box>
  );
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Modules() {
  const query = useQuery();
  const { courseId } = useParams();
  const { data: course } = useCourse(courseId);
  const { data: user = {} } = useUser();

  const moduleId = query.get('module');

  return (
    <>
      <Container maxW="container.lg">
        <Heading my={5} style={{ display: 'flex', alignItems: 'center' }}>
          {course?.title} Modules
        </Heading>
      </Container>
      <Container maxW="container.lg" centerContent>
        <SimpleGrid columns={[1, null, 3]} spacing={5} w="100%">
          {course?.modules &&
            course?.modules.map((module) => (
              <VideoAskWidget
                key={module.id}
                module={module}
                user={user}
                open={module.sort_order === parseInt(moduleId)}
              />
            ))}
        </SimpleGrid>
      </Container>
    </>
  );
}
