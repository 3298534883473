const Button = {
  variants: {
    primary: {
      colorScheme: "brand",
      bg: "brand.primary",
      color: "white",
    },
    secondary: {
      colorScheme: "brand",
      bg: "gray.200",
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

export default Button;
