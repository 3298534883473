const Urls = {
  apply: '/apply',
  applicationSuccess: '/application-success',
  basicProfile: '/basic-profile',
  basicProfileComplete: '/basic-profile-complete',
  candidateSummary: '/candidate/:uuid',
  career: '/career/:uuid',
  careerCategory: '/career-category',
  careerMatches: '/career-matches',
  lastStop: '/',
  company: '/company/:uuid',
  dashboard: '/dashboard',
  emailConfirmation: '/email/:uuid',
  idMeCallback: '/idme/callback',
  idMeLoginCallback: '/idme/callback-auth',
  register: '/register/:uuid',
  learn: '/learn',
  learnCallback: '/learn/callback',
  login: '/login',
  myTopCareers: '/my-top-careers',
  partnerVerify: '/:source/verify',
  share: '/share',
  skillbridge: '/skillbridge/:id',
  unverified: '/unverified',
  verify: '/verify',
  vetting: '/vetting',
  vettingRejected: '/vetting-complete',
  videoAskResult: '/videoask/:formId/:answer(yes|no)',
};

export default Urls;
