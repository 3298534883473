const environment = {
  development: {
    apiHost: 'http://localhost:8009/api/',
  },
  production: {
    apiHost: '/api/',
  },
}[process.env.NODE_ENV || 'development'];

module.exports = Object.assign(
  {
    zipcodeKey:
      'js-iD7WnWAUyK6i3ImFLDdrFzwvRIUI2u1RrcWZOFXHAPwibVqXSVAOjpIvDw936bbg',
  },
  environment
);
