import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { useField } from "formik";
import { IMaskMixin } from "react-imask";

const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => (
  <Input {...props} ref={inputRef} />
));

export default function PhoneField({ label, helperText, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { onChange, ...fieldRest } = field;
  return (
    <>
      <FormControl isInvalid={meta.error && meta.touched}>
        <FormLabel>{label}</FormLabel>
        <MaskedStyledInput
          {...fieldRest}
          {...props}
          mask={"{+1} 000-000-0000"}
          onAccept={(value) => helpers.setValue(value)}
          unmask={true}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </>
  );
}
