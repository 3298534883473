import { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import Spinner from '../layout/Spinner';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { FaCheck, FaCalendar, FaDownload } from 'react-icons/fa';

import {
  useUser,
  useClasses,
  useRegisterClass,
  useUnregisterClass,
  useMyClasses,
  useCalendlyAppointments,
} from '../../hooks/user';

import BookButton from './BookButton';

import ZoomIcon from './Zoom';

function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function formatScheduleForViewer(dayOfWeek, time) {
  // Define an array of week days, starting with Sunday as 0
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const viewerTimeZone = getUserTimezone();

  // Convert the day of week number to a name
  const dayName = days[dayOfWeek];

  // Use today's date for simplicity
  const today = new Date();
  const dateStr = today.toISOString().split('T')[0]; // Just the date part
  const dateTimeStr = `${dateStr}T${time}`; // Combine with time

  // Create a Date object in the New York timezone
  const options = {
    timeZone: 'America/New_York',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const nyTime = new Date(dateTimeStr).toLocaleTimeString('en-US', options);

  // Recreate the full date-time string for New York timezone to get the exact date and time
  const nyFullDateTime = new Date(`${dateStr}T${nyTime}`);

  // Format the time for the viewer's timezone using Intl.DateTimeFormat
  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: viewerTimeZone,
  }).format(nyFullDateTime);

  return `Every ${dayName} at ${formattedTime}`;
}

export default function Events() {
  const { data: events, isLoading: loadingEvents } = useClasses();
  const { data: myEvents, isLoading: loadingMyEvents } = useMyClasses();
  const { mutate: createEvent, isLoading } = useRegisterClass();
  const { mutate: cancelEvent, isLoading: loadingCancelEvent } =
    useUnregisterClass();
  const [registeringEventId, setRegisteringEventId] = useState(null);
  const {
    data: appointments,
    isLoading: loadingAppointments,
    refetch,
  } = useCalendlyAppointments();
  const { data: user, isLoading: loadingUser } = useUser();

  // Convert event time to a comparable format and check if within one hour
  const eventsWithJoinInfo = useMemo(() => {
    const now = new Date();
    console.log('Current date and time:', now.toString());

    // Helper function to convert JS day (0-6, Sun-Sat) to our system (0-6, Mon-Sun)
    const convertJSDayToOurSystem = (jsDay) => (jsDay === 0 ? 6 : jsDay - 1);

    if (!events || events.length === 0) return [];

    return events.map((event) => {
      console.log('Processing event:', event);
      const eventTime = new Date(now); // Start with current date/time
      const [hours, minutes, seconds] = event.time.split(':').map(Number);

      // Set the time of the event
      eventTime.setHours(hours, minutes, seconds, 0);
      console.log('Event time after setting hours:', eventTime.toString());

      // Get the current day in our system (0-6, where 0 is Monday)
      const currentDay = convertJSDayToOurSystem(now.getDay());
      const targetDay = event.day_of_week;
      console.log('Current day:', currentDay, 'Target day:', targetDay);

      let withinOneHour = false;
      let timeDiff = 0;

      if (currentDay === targetDay) {
        // If it's the same day, check if the event is within the next hour
        timeDiff = eventTime.getTime() - now.getTime();
        withinOneHour = timeDiff > 0 && timeDiff <= 3600000; // within 1 hour (3600000 ms)
        console.log('Same day event. Time difference (ms):', timeDiff);
      } else {
        // Calculate days until the next event
        let daysUntilEvent = targetDay - currentDay;
        if (daysUntilEvent <= 0) daysUntilEvent += 7;
        console.log('Days until event:', daysUntilEvent);

        eventTime.setDate(eventTime.getDate() + daysUntilEvent);
        console.log('Event time after adjusting day:', eventTime.toString());

        timeDiff = eventTime.getTime() - now.getTime();
        withinOneHour = timeDiff > 0 && timeDiff <= 3600000;
      }

      const registered = event?.registrations?.length > 0;

      console.log('Final calculated event time:', eventTime.toString());
      console.log('Time difference (ms):', timeDiff);
      console.log('Within one hour:', withinOneHour);

      return {
        ...event,
        withinOneHour,
        registered,
        nextOccurrence: eventTime,
      };
    });
  }, [events]);

  const myEventsWithJoinInfo = useMemo(() => {
    if (!myEvents || myEvents.length === 0) return [];
    const now = new Date();
    console.log('Current date and time:', now.toString());

    // Helper function to convert JS day (0-6, Sun-Sat) to our system (0-6, Mon-Sun)
    const convertJSDayToOurSystem = (jsDay) => (jsDay === 0 ? 6 : jsDay - 1);

    return myEvents.map((event) => {
      console.log('Processing event:', event);
      const eventTime = new Date(now); // Start with current date/time
      const [hours, minutes] = event.time.split(':').map(Number);

      // Set the time of the event
      eventTime.setHours(hours, minutes, 0, 0);
      console.log('Event time after setting hours:', eventTime.toString());

      // Get the current day in our system (0-6, where 0 is Monday)
      const currentDay = convertJSDayToOurSystem(now.getDay());
      const targetDay = event.day_of_week;
      console.log('Current day:', currentDay, 'Target day:', targetDay);

      let withinOneHour = false;
      let timeDiff = 0;

      if (currentDay === targetDay) {
        // If it's the same day, check if the event is within the next hour
        timeDiff = eventTime.getTime() - now.getTime();
        withinOneHour = timeDiff > 0 && timeDiff <= 3600000; // within 1 hour (3600000 ms)
        console.log('Same day event. Time difference (ms):', timeDiff);
      } else {
        // Calculate days until the next event
        let daysUntilEvent = targetDay - currentDay;
        if (daysUntilEvent <= 0) daysUntilEvent += 7;
        console.log('Days until event:', daysUntilEvent);

        eventTime.setDate(eventTime.getDate() + daysUntilEvent);
        console.log('Event time after adjusting day:', eventTime.toString());

        timeDiff = eventTime.getTime() - now.getTime();
        withinOneHour = timeDiff > 0 && timeDiff <= 3600000;
      }

      console.log('Final calculated event time:', eventTime.toString());
      console.log('Time difference (ms):', timeDiff);
      console.log('Within one hour:', withinOneHour);

      return {
        ...event,
        withinOneHour,
        nextOccurrence: eventTime,
      };
    });
  }, [myEvents]);

  const myAppointmentsWithJoinInfo = useMemo(() => {
    if (!appointments) return [];
    const now = new Date();
    console.log('Current date and time:', now.toString());

    return appointments.collection.map((event) => {
      const eventTime = new Date(event.start_time);
      console.log('Processing appointment:', event);
      console.log('Appointment time:', eventTime.toString());

      const timeDiff = eventTime.getTime() - now.getTime(); // Difference in milliseconds
      const withinOneHour = timeDiff > 0 && timeDiff <= 3600000; // within 1 hour

      const readableStart = format(
        parseISO(event.start_time),
        'MM-dd-yyyy h:mmaaa'
      );

      console.log('Time difference (ms):', timeDiff);
      console.log('Within one hour:', withinOneHour);

      return {
        ...event,
        withinOneHour,
        readableStart,
        nextOccurrence: eventTime,
      };
    });
  }, [appointments]);

  if (loadingEvents || loadingMyEvents || loadingAppointments || loadingUser) {
    return (
      <Container maxW="container.lg" centerContent>
        <Spinner />
      </Container>
    );
  }

  const anyStartingSoon = myEventsWithJoinInfo.filter(
    (event) => event.withinOneHour
  );

  return (
    <Box width="100%" height="100%" overflowY="auto" pb={10}>
      <Box pt={[2, 3, 6]}>
        <VStack spacing={4} pl={0} ml={0}>
          <Flex
            direction="row"
            align="left"
            w="100%"
            h="60px"
            bg="brand.secondary"
            as="a"
            href="/static/docs/interview-protocol.pdf"
            target="_blank"
          >
            <Box flex={1} position="relative">
              <Center h="100%">
                <Text
                  fontSize={['l', '2xl', '3xl', '3xl']}
                  color="brand.textWhite"
                  align="center"
                >
                  <Icon as={FaDownload} pr={1} pt={[1, 2, 2]} />
                  Download the Interview Protocol
                </Text>
              </Center>
            </Box>
          </Flex>
        </VStack>
      </Box>

      <Box maxH="100%" overflowY="auto" pb={10}>
        {user.profile.user_status === 'premium' && (
          <Container maxW="container.lg" pb={5}>
            <Heading my={5} style={{ display: 'flex', alignItems: 'center' }}>
              My Appointments
            </Heading>

            <BookButton handleEventScheduled={refetch} />
          </Container>
        )}

        {user.profile.user_status === 'premium' && (
          <Container maxW="container.lg" centerContent>
            <SimpleGrid columns={[1, null, 2]} spacing={5} w="100%">
              {myAppointmentsWithJoinInfo &&
                myAppointmentsWithJoinInfo.length > 0 &&
                myAppointmentsWithJoinInfo.slice(0, 3).map((event) => (
                  <Box
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    bg={event.withinOneHour ? 'brand.secondary' : 'white'}
                    color={event.withinOneHour ? 'white' : 'black'}
                    key={event.uri}
                  >
                    <Text mb={4}>
                      <Icon as={FaCalendar} pr={1} />
                      {event.readableStart}
                    </Text>
                    <HStack>
                      <Heading size="md">{event.name}</Heading>
                    </HStack>
                    <Text mt={4}>{event.description}</Text>
                    <Flex>
                      <Text mt={4}>
                        With{' '}
                        {event?.event_memberships?.length > 0 &&
                          event?.event_memberships[0].user_name}
                      </Text>
                      <Spacer />
                      {event.withinOneHour &&
                        event?.location?.type === 'zoom' && (
                          <a
                            href={event?.location?.join_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}
                          >
                            <Button
                              variant="outline"
                              bg="brand.primary"
                              color="white"
                            >
                              <ZoomIcon bg="brand.primary" w={6} h={6} pr={1} />
                              Join
                            </Button>
                          </a>
                        )}
                    </Flex>
                  </Box>
                ))}
            </SimpleGrid>
          </Container>
        )}

        {anyStartingSoon && anyStartingSoon.length > 0 && (
          <Container maxW="container.lg">
            <Heading my={5} style={{ display: 'flex', alignItems: 'center' }}>
              Join Now
            </Heading>
          </Container>
        )}

        {anyStartingSoon && anyStartingSoon.length > 0 && (
          <Container maxW="container.lg" centerContent>
            <SimpleGrid columns={[1, null, 2]} spacing={5} w="100%">
              {anyStartingSoon &&
                anyStartingSoon.length > 0 &&
                anyStartingSoon.slice(0, 3).map((event) => (
                  <Box
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    bg={event.withinOneHour ? 'brand.secondary' : 'white'}
                    color={event.withinOneHour ? 'white' : 'black'}
                    key={event.id}
                  >
                    <Text mb={4}>
                      <Icon as={FaCalendar} pr={1} />
                      {formatScheduleForViewer(event.day_of_week, event.time)}
                    </Text>
                    <HStack>
                      <Heading size="md">{event.title}</Heading>
                      {!event.withinOneHour && (
                        <>
                          <Spacer />
                          <Button
                            size="sm"
                            pl={2}
                            width="67%"
                            maxW="140px"
                            isLoading={
                              (isLoading ||
                                loadingCancelEvent ||
                                loadingEvents) &&
                              registeringEventId === event.id
                            }
                            isDisabled={
                              isLoading || loadingCancelEvent || loadingEvents
                            }
                            bg="brand.secondary"
                            onClick={() => {
                              setRegisteringEventId(event.id);
                              cancelEvent(event.id);
                            }}
                          >
                            <Icon as={FaCheck} pr={1} /> Registered
                          </Button>
                        </>
                      )}
                    </HStack>
                    <Text mt={4}>{event.description}</Text>
                    <Flex>
                      <Text mt={4}>Hosted by {event.hosted_by}</Text>
                      <Spacer />
                      {event.withinOneHour && (
                        <a
                          href={event.zoom_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          <Button
                            variant="outline"
                            bg="brand.primary"
                            color="white"
                          >
                            <ZoomIcon bg="brand.primary" w={6} h={6} pr={1} />
                            Join
                          </Button>
                        </a>
                      )}
                    </Flex>
                  </Box>
                ))}
            </SimpleGrid>
          </Container>
        )}

        <Container maxW="container.lg">
          <Heading my={5}>Weekly Interactive Workshops</Heading>
        </Container>
        {/* Other components remain unchanged */}
        <Container maxW="container.lg" centerContent pb={10}>
          <SimpleGrid columns={[1, null, 2]} spacing={5} w="100%">
            {eventsWithJoinInfo &&
              eventsWithJoinInfo.length > 0 &&
              eventsWithJoinInfo.map((event) => (
                <Box
                  p={5}
                  shadow="md"
                  borderWidth="1px"
                  bg={
                    event.registered && event.withinOneHour
                      ? 'brand.secondary'
                      : 'white'
                  }
                  color={
                    event.registered && event.withinOneHour ? 'white' : 'black'
                  }
                  key={event.id}
                >
                  <Text mb={4}>
                    <Icon as={FaCalendar} pr={1} />
                    {formatScheduleForViewer(event.day_of_week, event.time)}
                  </Text>
                  <HStack>
                    <Heading size="md">{event.title}</Heading>
                    {(!event.withinOneHour || !event.registered) && (
                      <>
                        <Spacer />
                        <Button
                          size="sm"
                          pl={2}
                          width="67%"
                          maxW="140px"
                          isLoading={
                            (isLoading ||
                              loadingCancelEvent ||
                              loadingEvents) &&
                            registeringEventId === event.id
                          }
                          isDisabled={
                            isLoading || loadingCancelEvent || loadingEvents
                          }
                          bg={
                            event?.registered
                              ? 'brand.secondary'
                              : 'brand.primary'
                          }
                          onClick={() => {
                            setRegisteringEventId(event.id);
                            if (event.registered > 0) {
                              cancelEvent(event.id);
                            } else {
                              createEvent(event.id);
                            }
                          }}
                        >
                          {event.registered ? (
                            <>
                              <Icon as={FaCheck} pr={1} /> Registered
                            </>
                          ) : (
                            'Register'
                          )}
                        </Button>
                      </>
                    )}
                  </HStack>
                  <Text mt={4}>{event.description}</Text>
                  <Flex>
                    <Text mt={4}>Hosted by {event.hosted_by}</Text>
                    <Spacer />
                    {event.registered && event.withinOneHour && (
                      <a
                        href={event.zoom_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant="outline"
                          bg="brand.primary"
                          color="white"
                        >
                          <ZoomIcon bg="brand.primary" w={6} h={6} pr={1} />
                          Join
                        </Button>
                      </a>
                    )}
                  </Flex>
                </Box>
              ))}
          </SimpleGrid>
        </Container>
      </Box>
    </Box>
  );
}
