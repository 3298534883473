import React from 'react';
import { Box, Center, Heading } from '@chakra-ui/react';
import ProfileForm from './BasicProfileForm';

export default function Profile() {
  return (
    <Box
      border="1px"
      borderColor="gray.200"
      bgColor="gray.100"
      h="100vh"
      overflow="auto"
      pb="50px"
    >
      <Box p={3} bgColor="brand.primary">
        <Heading as="h1" size="lg" pb="2" color="brand.textWhite">
          Complete Your Profile
        </Heading>
      </Box>

      <Center>
        <Box
          p={[1, 5, 5]}
          m={[0, 10, 10]}
          maxW="1100px"
          bgColor="white"
          boxShadow="base"
          s
        >
          <ProfileForm />
        </Box>
      </Center>
    </Box>
  );
}
