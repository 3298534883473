import React, { useEffect, useState, useMemo } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { Button, Divider, Grid, GridItem, Heading } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Redirect } from 'react-router-dom';

import PhoneField from '../form/PhoneField';
import Error from '../form/Error';
import DateField from '../form/DateField';
import SelectField from '../form/SelectField';

import RadioGroupField from '../form/RadioGroupField';
import { useUpdateUser, useUser } from '../../hooks/user';
import {
  getEducationLevels,
  getSecurityClearanceLevels,
  getPayGrades,
} from '../../queries/form-data';
import { getBasicProfileValidationSchema } from './validation';
import Urls from '../../app/urls';
import {
  BranchOfService,
  Ethnicity,
  Gender,
  // States,
} from '../../app/constants';
import InputField from '../form/InputField';

const userTypeOptions = [
  {
    value: 'Active Duty',
    label: 'Active Duty',
  },
  { value: 'Veteran', label: 'Veteran' },
  { value: 'National Guard/Reserves', label: 'National Guard/Reserves' },
];

const smsOptions = [
  {
    value: 'true',
    label: 'Yes',
  },
  { value: 'false', label: 'No' },
];

const commTypeOptions = [
  {
    id: 'Email',
    label: 'Email',
  },
  { id: 'Text Message', label: 'Text Message' },
];

const useDynamicValidationSchema = (serviceStatus) => {
  const [schema, setSchema] = useState(
    getBasicProfileValidationSchema(serviceStatus)
  );

  useEffect(() => {
    setSchema(getBasicProfileValidationSchema(serviceStatus));
  }, [serviceStatus]);

  return schema;
};

const FormImpl = ({ error, isLoading, setServiceStatus }) => {
  const { values, errors, submitCount } = useFormikContext();

  const { data: educationLevels } = useQuery(
    'educationLevels',
    getEducationLevels,
    {
      initialData: [],
    }
  );

  const { data: securityClearanceLevels } = useQuery(
    'securityClearanceLevels',
    getSecurityClearanceLevels,
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const { data: payGrades } = useQuery('payGrades', getPayGrades, {
    initialData: [],
  });

  const conditionalValues = useMemo(() => {
    const vals = {
      pay_grade_label: 'Pay Grade*',
      transition_date_helper:
        'Enter the date you will transition from active duty service (ETS, EOS, EAOS)',
      job_title_label:
        'What Job Title or Career Path are you pursuing post-transition?*',
      job_title_helper: 'If you are unsure enter "I don\'t know',
      date_available_helper: 'This may be different than your transition date.',
      date_available_label:
        'What date are you available for Full-time Employment?*',
      mil_occupation_helper: 'Please tell us what job you do in the military.',
      mil_occupation_description_helper:
        "In laymen's terms what job do you do in the military?",
      years_experience_helper:
        'Enter the total years of active duty service you will have completed when you separate from the military.',
    };

    if (values.service_status === 'Veteran') {
      vals.pay_grade_label = 'Pay Grade at Separation/Retirement*';
      vals.transition_date_helper =
        'Enter the date you transitioned from active duty service (ETS, EOS, EAOS)';

      vals.job_title_helper = 'If you are unemployed enter "unemployed"';
      vals.job_title_label = 'Current Job Title*';
      vals.date_available_helper = '';
      vals.date_available_label =
        'What date are you available for Full-time Employment?*';
      vals.mil_occupation_helper =
        'Please tell us what job you did in the military.';
      vals.mil_occupation_description_helper =
        "In laymen's terms what job did you do in the military?";
      vals.years_experience_helper =
        'Enter the total years of active duty service you completed when you separated/retired from the military.';
    }

    if (values.service_status === 'National Guard/Reserves') {
      vals.job_title_label = 'Current or Most Recent Civilian Job Title*';
      vals.job_title_helper = 'If you are unemployed enter “unemployed”';
      vals.date_available_helper = '';
      vals.date_available_label =
        'What date are you available for Full-time Employment?*';
      vals.years_experience_helper =
        'As of today, how many years have you served in the Armed Forces?';
    }

    setServiceStatus(values.service_status);

    return vals;
  }, [setServiceStatus, values.service_status]);

  return (
    <Form>
      <Grid templateColumns="repeat(3, 1fr)" gap={5}>
        <GridItem colSpan={3}>
          <Heading size="md">As of today, I am</Heading>
        </GridItem>
        <GridItem colSpan={3} pb={5}>
          <RadioGroupField name="service_status" options={userTypeOptions} />
        </GridItem>
      </Grid>

      {values?.service_status && (
        <Grid templateColumns="repeat(3, 1fr)" gap={5}>
          <GridItem colSpan={3}>
            <Divider />
          </GridItem>

          <GridItem colSpan={3}>
            <Heading size="md">Profile</Heading>
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <InputField
              name="email"
              label="Email*"
              placeholder="Enter your email"
              helperText="Enter your primary email address."
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <InputField
              name="email_confirm"
              label="Confirm Email*"
              placeholder="Confirm your email"
              helperText="Confirm your primary email address."
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <PhoneField
              name="phone"
              label="Mobile Phone*"
              placeholder="Enter your mobile phone"
              maxLength={48}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <RadioGroupField
              label="SMS/Email Opt-in*"
              helperText="I agree to receive SMS/Email communications from Transition Overwatch."
              name="sms_opt_in"
              options={smsOptions}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <SelectField
              name="preferred_communication_type"
              label="Communication Method*"
              labelKey="label"
              placeholder="Select communcation method"
              helperText="Tell us how you prefer to be contacted."
              options={commTypeOptions}
            />
          </GridItem>

          {values.service_status !== 'National Guard/Reserves' && (
            <GridItem colSpan={[3, 1, 1]} pb={5}>
              <DateField
                name="separation_date"
                helperText={conditionalValues.transition_date_helper}
                label="Transition Date*"
              />
            </GridItem>
          )}

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <SelectField
              name="pay_grade"
              label={conditionalValues.pay_grade_label}
              labelKey="name"
              placeholder="Select your pay grade"
              options={payGrades}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <SelectField
              name="security_clearance_level"
              label="Active Security Clearance*"
              labelKey="name"
              options={securityClearanceLevels}
            />
          </GridItem>

          <GridItem colSpan={1} pb={5}>
            <SelectField
              name="branch_of_service"
              label="Branch of Service*"
              labelKey="name"
              placeholder="Select branch"
              options={BranchOfService}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <InputField
              name="military_occupation"
              label="Military Occupation Code*"
              placeholder="Enter your occupation"
              maxLength={64}
              helperText={conditionalValues.mil_occupation_helper}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <InputField
              name="military_occupation_desc"
              label="Military Occupation Description*"
              placeholder="Enter your occupation description"
              maxLength={128}
              helperText={conditionalValues.mil_occupation_description_helper}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <InputField
              name="total_years_of_service"
              label="Total Years of Military Service*"
              placeholder="Enter years of service..."
              helperText={conditionalValues.years_experience_helper}
              maxLength={2}
            />
          </GridItem>

          <GridItem colSpan={3}>
            <Divider />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading size="md">About You</Heading>
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <SelectField
              name="gender"
              label="Gender*"
              labelKey="name"
              placeholder="Select gender"
              helperText="What gender do you identify as?"
              options={Gender}
            />
          </GridItem>

          {values.gender === 'other' && (
            <GridItem colSpan={[3, 1, 1]} pb={5}>
              <InputField
                name="gender_other"
                label="Gender Other*"
                placeholder="Enter gender"
                helperText="Please specify your gender."
              />
            </GridItem>
          )}

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <SelectField
              name="ethnicities"
              label="Ethnicity*"
              labelKey="name"
              isMulti
              placeholder="Select ethnicity"
              helperText="Please specify your ethnicity. (select all that apply)"
              options={Ethnicity}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <SelectField
              name="education_level"
              label="Degree*"
              labelKey="name"
              // helperText="Select the highest level of completed education."
              options={educationLevels}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <InputField
              name="current_location"
              label="Where do you currently live?*"
              placeholder="Enter your location"
              maxLength={64}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <InputField
              name="preferred_location"
              label="Preferred Employment Location*"
              placeholder="Enter your preferred location"
              helperText="For multiple locations, separate with a comma."
              maxLength={64}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <InputField
              name="job_title"
              label={conditionalValues.job_title_label}
              helperText={conditionalValues.job_title_helper}
              placeholder="Job Title"
              maxLength={64}
            />
          </GridItem>

          <GridItem colSpan={[3, 1, 1]} pb={5}>
            <DateField
              name="date_available"
              helperText={conditionalValues.date_available_helper}
              label={conditionalValues.date_available_label}
            />
          </GridItem>

          <GridItem colSpan={3} pb={5}>
            <Error error={error} formErrors={submitCount > 0 && errors} />
          </GridItem>

          <GridItem
            pb="3"
            d="flex"
            alignItems="baseline"
            colStart={3}
            colSpan={1}
          >
            <Button isLoading={isLoading} w="100%" type="submit">
              Submit
            </Button>
          </GridItem>
        </Grid>
      )}
    </Form>
  );
};

export default function ProfileForm() {
  const { data: user } = useUser();
  const [serviceStatus, setServiceStatus] = useState();

  const { isLoading, error, mutate: updateUser } = useUpdateUser();
  const validationSchema = useDynamicValidationSchema(serviceStatus);

  const profile = user?.profile || {};

  if (user?.profile?.has_completed_profile)
    return <Redirect to={Urls.BasicProfileComplete} />;

  return (
    <Formik
      initialValues={{
        email: '',
        sms_opt_in: '',
        ethnicities: profile.ethnicities || '',
        gender: profile.gender || '',
        gender_other: profile.gender_other || '',
        active_security_clearance: profile.active_security_clearance || false,
        branch_of_service: profile.branch_of_service || '',
        phone: profile.phone || '',
        state: profile.state || '',
        education_level: profile.education_level?.id || '',
        service_status: profile.service_status || '',
        job_title: profile.job_title || '',
        date_available: profile.date_available || '',
        pay_grade: profile.pay_grade?.id || '',
        preferred_communication_type:
          profile.preferred_communication_type || '',
        security_clearance_level: profile?.security_clearance_level?.id || '',
        separation_date: profile.separation_date || '',
        basicProfile: true,
      }}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(update, actions) => {
        actions.setSubmitting(true);
        console.log(update);
        const separation_date =
          typeof update.separation_date === 'string'
            ? update.separation_date
            : format(update.separation_date, 'yyyy-MM-dd');

        const payload = {
          userId: user.uuid,
          update: {
            ...update,
            sms_opt_in: update.sms_opt_in === 'true',
            separation_date,
            date_available:
              typeof update.date_available === 'string'
                ? update.date_available
                : format(update.date_available, 'yyyy-MM-dd'),
          },
          redirect: Urls.assessment,
        };

        if (update.service_status === 'National Guard/Reserves') {
          delete payload.update.separation_date;
        }

        console.log(payload);

        updateUser(payload);
      }}
    >
      <FormImpl
        error={error}
        isLoading={isLoading}
        setServiceStatus={setServiceStatus}
      />
    </Formik>
  );
}
