import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { useConfirmEmail } from '../../hooks/user';
import { Text } from '@chakra-ui/react';
import CardContainer from '../layout/CardContainer';
import { Flex, Spacer, Button } from '@chakra-ui/react';
import Urls from '../../app/urls';
import { Link } from 'react-router-dom';

export default function EmailConfirmation() {
  const { uuid } = useParams();
  const { mutate, isSuccess, error } = useConfirmEmail();

  useEffect(() => {
    if (uuid) {
      const result = mutate(uuid);
      console.log(result);
    }
  }, [uuid, mutate]);

  if (error) {
    return (
      <CardContainer heading="Invalid Confirmation Link">
        <Text pb={2} pl={2}>
          We could not confirm your email address. Please try again.
        </Text>
      </CardContainer>
    );
  }

  if (isSuccess) {
    return (
      <CardContainer heading="Email Confirmed!">
        <Text pb={2} pl={2}>
          Your email has been confirmed. Thank you for registering with us!
        </Text>
        {/* <Text pb={2} pl={2}>
          Our team is currently reviewing your registration and you can expect
          to hear from us within 5 business days. In the mean time, spread the
          word to your battle buddies, shipmates, and wingmen for the chance to
          earn a $300 referral bonus.
        </Text> */}
        {/* <Flex>
          <Spacer />
          <Button as={Link} to="/dashboard/bonus-program">
            Learn More
          </Button>
        </Flex> */}
      </CardContainer>
    );
  }

  return <Spinner />;
}
