import apiClient from '../app/apiClient';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import history from '../app/history';
import Urls from '../app/urls';

export const getUser = async () => {
  const { data } = await apiClient.get('users');
  return data;
};

const getUserName = async (uuid) => {
  const { data } = await apiClient.post('idme/username', { uuid });
  return data?.username;
};

const getConfig = async (uuid) => {
  const { data } = await apiClient.get('config');
  return data;
};

const getClasses = async () => {
  const { data } = await apiClient.get('classes');
  return data;
};

const getMyClasses = async () => {
  const { data } = await apiClient.get('my-classes');
  return data;
};

const getCalendlyAppointments = async () => {
  const { data } = await apiClient.get('calendly/appointments');
  return data;
};

const unregisterClass = async (classId) => {
  const { data } = await apiClient.delete(`classes/${classId}/unregister`);
  return data;
};

const registerClass = async (classId) => {
  const { data } = await apiClient.post(`classes/${classId}/register`);
  return data;
};

const loginUser = async (values) => {
  try {
    const response = await apiClient.post('token/', { ...values });

    const { data } = response;
    localStorage.setItem('access_token', data?.access);
    localStorage.setItem('refresh_token', data?.refresh);

    history.push(Urls.BasicProfileComplete);
    return data;
  } catch (e) {
    throw new Error('Invalid Login');
  }
};

const registerUser = async (values) => {
  const response = await apiClient.post('users', values);

  const { data } = response;
  localStorage.setItem('access_token', data?.access);
  localStorage.setItem('refresh_token', data?.refresh);
  history.push(Urls.basicProfile);
};

const mutateUser = async ({ userId, update, redirect = false }) => {
  const response = await apiClient.patch(`users/${userId}/profile`, update);

  if (redirect) history.push(redirect);
  return response.data;
};

const mutateCourseCompleted = async ({ courseId }) => {
  const { data } = await apiClient.patch(`courses/${courseId}/completed`, {
    course_id: courseId,
  });

  return data;
};

const getCourses = async () => {
  const response = await apiClient.get('courses');
  return response.data;
};

const getCourse = async (courseId) => {
  const response = await apiClient.get(`courses/${courseId}`);
  return response.data;
};

const mutateApplicationSubmitted = async ({ userId, careerUuid }) => {
  const response = await apiClient.patch(
    `users/${userId}/application_submitted`,
    { career_uuid: careerUuid }
  );

  return response.data;
};

const mutateMyTopCareers = async ({ userId, update, redirect = false }) => {
  const response = await apiClient.patch(
    `users/${userId}/my-top-careers`,
    update
  );

  if (redirect) history.push(redirect);
  return response.data;
};

const mutateEmailConfirmation = async (uuid) => {
  const response = await apiClient.post(`email/${uuid}`);

  return response.data;
};

const deleteMyTopCareers = async (myCareerId) => {
  const response = await apiClient.delete(`my-top-careers/${myCareerId}`);

  return response.data;
};

export const uploadResume = async ({ userId, file }) => {
  const formData = new FormData();
  formData.append('resume', file);
  const response = await apiClient.post(`resumes`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
};

const idmeVerify = async (uuid) => {
  const source = localStorage.getItem('source');
  const { data } = await apiClient.post('idme/verify', {
    code: uuid,
    source,
  });

  localStorage.setItem('access_token', data?.tokens?.access);
  localStorage.setItem('refresh_token', data?.tokens?.refresh);
  history.push(Urls.dashboard);

  return data;
};

const idmeAuth = async (uuid) => {
  const { data } = await apiClient.post('idme/auth', {
    code: uuid,
  });

  localStorage.setItem('access_token', data?.tokens?.access);
  localStorage.setItem('refresh_token', data?.tokens?.refresh);
  history.push(Urls.dashboard);

  return data;
};

export function useUser() {
  return useQuery('users', getUser, {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: 1000 * 60 * 60,
    onError: () => {
      window.location.replace('/login');
    },
  });
}

export function useUsername(uuid) {
  const result = useQuery('username', () => getUserName(uuid), {
    retry: false,
  });
  return result;
}

export function useConfig() {
  const result = useQuery('config', getConfig, {
    retry: false,
  });
  return result;
}

export function useUpdateUser(options = {}) {
  const queryClient = useQueryClient();
  return useMutation(mutateUser, {
    onSuccess: (data) => {
      queryClient.setQueryData('users', data);
      if (options && options?.onSuccess) options.onSuccess(data);
    },
  });
}

export function useUpdateApplicationSubmitted(options = {}) {
  // return useMutation(mutateApplicationSubmitted);
  const queryClient = useQueryClient();
  return useMutation(mutateApplicationSubmitted, {
    onSuccess: (data) => {
      queryClient.setQueryData('users', data);
    },
  });
}

export function useUpdateMyTopCareers() {
  const queryClient = useQueryClient();
  return useMutation(mutateMyTopCareers, {
    onSuccess: (data) => {
      queryClient.setQueryData('users', data);
    },
  });
}

export function useConfirmEmail() {
  const queryClient = useQueryClient();
  return useMutation(mutateEmailConfirmation, {
    onSuccess: (data) => {
      queryClient.setQueryData('emailConfirmation', data);
    },
  });
}

export function useDeleteMyTopCareer() {
  const queryClient = useQueryClient();
  return useMutation(deleteMyTopCareers, {
    onSuccess: (data) => {
      queryClient.setQueryData('users', data);
    },
  });
}

export function useRegisterUser() {
  return useMutation(registerUser);
}

export function useLoginUser() {
  return useMutation(loginUser);
}

export function useIdmeVerify() {
  const queryClient = useQueryClient();
  return useMutation(idmeVerify, {
    onSuccess: (data) => {
      queryClient.setQueryData('idme', data);
    },
  });
}

const videoAskCode = async (code) => {
  const { data } = await apiClient.post('trainings/code', {
    code,
  });

  return data;
};

export function useVideoAskCode() {
  return useMutation(videoAskCode);
}

export function useCourses() {
  return useQuery('courses', getCourses, {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: 1000 * 60 * 31,
  });
}

export function useCourse(courseId) {
  return useQuery(['courses', courseId], () => getCourse(courseId), {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: 1000 * 60 * 31,
  });
}

export function useCourseCompleted() {
  const queryClient = useQueryClient();
  return useMutation(mutateCourseCompleted, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('courses');
    },
  });
}

export function useIdmeAuth() {
  const queryClient = useQueryClient();
  return useMutation(idmeAuth, {
    onSuccess: (data) => {
      queryClient.setQueryData('idme', data);
    },
  });
}

export function useClasses() {
  return useQuery('classes', getClasses, {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: 1000 * 60 * 31,
  });
}

export function useMyClasses() {
  return useQuery('my-classes', getMyClasses, {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: 1000 * 60 * 31,
  });
}

export function useRegisterClass() {
  const queryClient = useQueryClient();
  return useMutation(registerClass, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('classes');
      queryClient.invalidateQueries('my-classes');
    },
  });
}

export function useUnregisterClass() {
  const queryClient = useQueryClient();
  return useMutation(unregisterClass, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('classes');
      queryClient.invalidateQueries('my-classes');
    },
  });
}

export function useCalendlyAppointments() {
  return useQuery('calendly-appointments', getCalendlyAppointments, {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: 1000 * 60 * 31,
  });
}
