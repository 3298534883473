import React from "react";
import {
  Alert,
  AlertTitle,
  AlertDescription,
  Box,
  AlertIcon,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const ErrorMessage = ({ error, formErrors }) => {
  if (typeof error === "string") return <Text>{error}</Text>;
  if (typeof error?.message === "string")
    return (
      <Text>
        {error.name || "Error"}: {error.message}
      </Text>
    );

  // if (error?.messages instanceof Array) {
  //   return (
  //     <>
  //       {Object.keys(error.messages).map((message, key) => {
  //         return (
  //           <Text key={key}>
  //             {message.charAt(0).toUpperCase() + message.slice(1)}:{" "}
  //             {message[key]}
  //           </Text>
  //         );
  //       })}
  //     </>
  //   );
  // }

  if (typeof error === "object" && error !== null) {
    return (
      <>
        {Object.keys(error).map((message, key) => {
          return (
            <Text key={key}>
              {message.charAt(0).toUpperCase() + message.slice(1)}:{" "}
              {error[message]}
            </Text>
          );
        })}
      </>
    );
  }

  return <Text>Error</Text>;
};

export default function FormError({ error, formErrors, ...props }) {
  if (!error && (isEmpty(formErrors) || !formErrors)) return null;

  if (formErrors && !isEmpty(formErrors)) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>Please fix the following issues:</AlertTitle>

          <AlertDescription display="block">
            <UnorderedList>
              {Object.keys(formErrors).map((key) => (
                <ListItem key={key}>{formErrors[key]}</ListItem>
              ))}
            </UnorderedList>
          </AlertDescription>
        </Box>
      </Alert>
    );
  }
  return (
    <Box {...props}>
      <Alert status="error">
        <AlertIcon />
        <ErrorMessage error={error} />
      </Alert>
    </Box>
  );
}
