import axios from 'axios';
import { apiHost } from '../config';
import history from './history';

const axiosInstance = axios.create({
  baseURL: apiHost,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
      config.headers['Content-Type'] = 'application/json';
      config.headers['Accept'] = 'application/json';
    }

    return config;
  },
  (error) => {
    return parseError(error.response.data);
  }
);

function parseError(messages) {
  // error
  if (messages) {
    return Promise.reject(messages);
  } else {
    return Promise.reject({ messages: 'Failed request' });
  }
}

function parseBody(response) {
  //  if (response.status === 200 && response.data.status.code === 200) { // - if use custom status code
  if (response.status < 300) {
    return response;
  } else {
    return parseError(response.data.messages);
  }
}

axiosInstance.interceptors.response.use(
  (response) => {
    return parseBody(response);
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error?.response?.status === 401 &&
      originalRequest.url === '/token/refresh/'
    ) {
      history.push('/login');
      return Promise.reject(error);
    }

    if (
      error?.response?.status === 401 &&
      originalRequest.url === '/token/refresh/'
    ) {
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refresh_token');
      return axiosInstance
        .post('/token/refresh/', {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem('access_token', res.data.access);
            axiosInstance.defaults.headers.common['Authorization'] =
              'Bearer ' + res.data.access;
            originalRequest.headers['Authorization'] =
              'Bearer ' + res.data.access;
            return axios(originalRequest);
          }
        });
    }

    if (error?.response?.status >= 400) {
      return parseError(error.response.data);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
