import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';
import { IntercomProvider } from 'react-use-intercom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import theme from './theme/';

const queryClient = new QueryClient();

const INTERCOM_APP_ID = 'c1rszckm';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log('Running in dev mode');
} else {
  Sentry.init({
    dsn: 'https://750dad90f1334ed78895f676566490fb@o1121912.ingest.sentry.io/6159085',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </IntercomProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
