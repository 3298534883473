import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';

import { Link } from 'react-router-dom';

import { useCourses } from '../../hooks/user';

export default function Courses() {
  // const { data: courses } = useCourses();

  return (
    <>
      {/* <Container maxW="container.lg">
        <Heading my={5} style={{ display: 'flex', alignItems: 'center' }}>
          Podcast
        </Heading>
      </Container> */}

      {/* <Container maxW="container.lg" centerContent> */}
      {/* <Box p={2} pb={5} overflowY="auto" maxH="2000px"> */}
      <iframe
        title="podcastPlayer"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '6px',
        }}
        frameBorder="no"
        scrolling="yes"
        seamless
        src="https://player.captivate.fm/show/9733b798-bc24-44a8-9f47-de150d260bc1"
      />
      {/* </Box> */}

      {/* </Container> */}
    </>
  );
}
