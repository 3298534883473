import React from 'react';
import { Box, Center, Heading, SimpleGrid, Spinner } from '@chakra-ui/react';

export default function CardContainer({
  heading,
  headingSize = 'lg',
  isLoading = false,
  subheading,
  children,
}) {
  if (isLoading)
    return (
      <Center h="80%" w="100%">
        <Spinner size="xl" />
      </Center>
    );
  return (
    <Center h={{ sm: '100%', md: '80%', lg: '70%' }}>
      <SimpleGrid maxWidth="600px" border="1px" borderColor="gray.200">
        <Box bg="brand.primary" pl={15} pr={15} pt={2} pb={2}>
          <Heading size={headingSize} color="brand.textWhite">
            {heading}
          </Heading>
          <Box mt={2} color="brand.textWhite">
            {subheading}
          </Box>
        </Box>
        <Box pl={15} pr={15} pb={3} pt={3} bgColor="white">
          {children}
        </Box>
      </SimpleGrid>
    </Center>
  );
}
