import { atom, selector } from 'recoil';

export const statesFilterState = atom({
  key: 'statesFilterState',
  default: [],
});

export const categoryFilterState = atom({
  key: 'categoryFilterState',
  default: [],
});

export const industryFilterState = atom({
  key: 'industryFilterState',
  default: [],
});

export const pageNumState = atom({
  key: 'pageNumState',
  default: 1,
});

export const totalPageState = atom({
  key: 'totalPageState',
  default: 1,
});

export const pageLimitState = atom({
  key: 'pageLimitState',
  default: 1,
});

export const directHireFilterState = atom({
  key: 'directHireFilterState',
  default: true,
});

export const internshipFilterState = atom({
  key: 'internshipFilterState',
  default: true,
});

export const directHireFilter = selector({
  key: 'directHireFilter',
  get: ({ get }) => {
    return get(directHireFilterState);
  },
});

export const internshipFilter = selector({
  key: 'internshipFilter',
  get: ({ get }) => {
    return get(internshipFilterState);
  },
});

export const careerSearchState = atom({
  key: 'careerSearchState',
  default: '',
});
