import { useMemo, useEffect, useState } from 'react';
import { Box, HStack, Icon, Link } from '@chakra-ui/react';
import {
  Link as RouterLink,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { useUser } from '../../hooks/user';
import SendbirdChat from '@sendbird/chat';
import { OpenChannelModule } from '@sendbird/chat/openChannel';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import {
  FaBriefcase,
  FaUserTie,
  FaBuilding,
  FaPodcast,
  FaClipboardList,
  FaHome,
  FaCalendarAlt,
  FaEnvelope,
  FaBook,
  FaGift,
} from 'react-icons/fa';

const Links = [
  {
    url: '',
    label: 'Home',
    icon: FaHome,
    display: 'none',
    status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  },
  {
    url: '/schedule',
    label: 'Schedule',
    icon: FaCalendarAlt,
    display: 'inline',
    status: ['showcase', 'basic', 'premium'],
  },
  {
    url: '/chat',
    label: 'Chat',
    display: 'inline',
    icon: FaEnvelope,
    status: ['showcase', 'premium'],
  },
  {
    url: '/courses',
    label: 'Courses',
    display: 'inline',
    icon: FaBook,
    status: ['learn', 'showcase', 'basic', 'premium'],
  },
  // {
  //   url: '/jobs',
  //   label: 'Jobs',
  //   display: 'inline',
  //   icon: FaClipboardList,
  //   status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  // },
  {
    url: '/listen',
    label: 'Listen',
    display: 'none',
    icon: FaPodcast,
    status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  },
  {
    url: '/bonus-program',
    label: 'Bonus',
    display: 'none',
    icon: FaGift,
    status: ['apply', 'learn', 'showcase', 'basic', 'premium'],
  },
];

function useSendbird(userId) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!userId) return;

    const sb = SendbirdChat.init({
      appId: '1B1D6000-ACB3-4D44-BFF0-B0EA58D4E0E4',
      modules: [new OpenChannelModule(), new GroupChannelModule()],
    });

    const getMessageCount = async () => {
      try {
        await sb.connect(userId);
        const unreadCount = await sb.groupChannel.getTotalUnreadMessageCount();
        setCount(unreadCount);
      } catch (error) {
        console.error('Failed to get message count:', error);
      }
    };

    getMessageCount(); // Call it immediately for the first time.

    const intervalId = setInterval(getMessageCount, 20000); //

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts or userId changes
    };
  }, [userId]);

  return count;
}

export default function Navigation() {
  const { data: user = {} } = useUser();
  let { path } = useRouteMatch();
  const location = useLocation();
  const messageCount = useSendbird(user && user?.sendbird_account_uuid);

  const filteredLinks = useMemo(() => {
    return Links.filter((r) => r.status.includes(user?.profile?.user_status));
  }, [user]);

  return (
    <HStack
      spacing={{ base: 2, md: 4 }}
      bg="brand.primary"
      w="100%"
      justifyContent="center"
    >
      {filteredLinks.map((link) => (
        <Link
          as={RouterLink}
          to={path + link.url}
          key={link.url}
          color="white"
          _focus={{ outline: 'none' }}
          pt={{ base: '10px', md: '2px' }}
          pr={{ base: '9px', md: '10px' }}
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }} // Stack items vertically on small screens, and horizontally on medium screens and above
          alignItems="center"
          textAlign="center" // Center align the text, especially for the base case where it's under the icon
        >
          <Icon
            fontSize={{ base: '18px', md: '24px' }}
            as={link.icon}
            color={
              location.pathname === path + link.url
                ? 'brand.secondary'
                : 'white'
            }
          />{' '}
          {/* Larger icons on smaller screens */}
          <Box
            as="span"
            mt={{ base: '2', md: '0' }}
            ml={{ md: '2' }}
            fontSize={{ base: '12px', md: '24px' }}
          >
            {link.label} {link.label === 'Chat' && `(${messageCount})`}
          </Box>
        </Link>
      ))}
    </HStack>
  );
}
