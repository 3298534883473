import apiClient from "../app/apiClient";

export const getAutonomyLevels = async () => {
  const { data } = await apiClient.get("autonomy-levels");
  return data;
};

export const getCompanySizes = async () => {
  const { data } = await apiClient.get("company-sizes");
  return data;
};

export const getCompanyTypes = async () => {
  const { data } = await apiClient.get("company-types");
  return data;
};

export const getCompStructures = async () => {
  const { data } = await apiClient.get("comp-structures");
  return data;
};

export const getDynamismLevels = async () => {
  const { data } = await apiClient.get("dynamism-levels");
  return data;
};

export const getFormalityLevels = async () => {
  const { data } = await apiClient.get("formality-levels");
  return data;
};


export const getIndustries = async () => {
  const { data } = await apiClient.get("industries");
  return data;
};

export const getResponsibilityLevels = async () => {
  const { data } = await apiClient.get("responsibility-levels");
  return data;
};

export const getSecurityClearanceLevels = async () => {
  const { data } = await apiClient.get("clearance-levels");
  return data;
};

export const getWorkExperienceLevels = async () => {
  const { data } = await apiClient.get("work-experience-levels");
  return data;
};

export const getWorkSettings = async () => {
  const { data } = await apiClient.get("work-settings");
  return data;
};

export const getCareerCategories = async () => {
  const { data } = await apiClient.get("career-categories");
  return data;
};

export const getEducationLevels = async () => {
  const { data } = await apiClient.get("education-levels");
  return data;
};

export const getPayGrades = async () => {
  const { data } = await apiClient.get("pay-grades");
  return data;
};

export const getPersonalityAttributes = async () => {
  const { data } = await apiClient.get("personality-attributes");
  return data;
};

export const getDisciplines = async () => {
  const { data } = await apiClient.get("disciplines");
  return data;
};

export const getSalaryRanges = async () => {
  const { data } = await apiClient.get("salary-ranges");
  return data;
};
