import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FaHome, FaSignOutAlt } from 'react-icons/fa';

import { Image } from '@chakra-ui/react';

import { useUser } from '../../hooks/user';
import Urls from '../../app/urls';

const Header = () => {
  const { data: user } = useUser();

  const logoWidth = useBreakpointValue({ base: '150', md: '190' });
  const username = user
    ? user?.first_name && user?.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username
    : null;

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      p={2}
    >
      <Flex align="center">
        <Image
          p={2}
          fallback={<div />}
          src={process.env.PUBLIC_URL + '/images/to-logo.png'}
          alt="Transition Overwatch Logo"
          htmlWidth={logoWidth}
        />
      </Flex>

      {username && (
        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            rightIcon={<ChevronDownIcon />}
          >
            {username}
          </MenuButton>
          <MenuList>
            {user?.profile?.user_status !== 'apply' && (
              <MenuItem as={Link} to={Urls.dashboard}>
                <Icon as={FaHome} color="brand.500" mr={1} /> Home
              </MenuItem>
            )}

            {/* {user && user?.profile?.has_completed_profile && (
              <MenuItem as={Link} to={Urls.basicProfileComplete}>
                <Icon as={FaShareSquare} color="brand.500" mr={1} /> Share
              </MenuItem>
            )} */}

            <MenuItem as={Link} to={Urls.login}>
              <Icon as={FaSignOutAlt} color="brand.500" mr={1} /> Logout
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};

export default Header;
