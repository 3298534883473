import { extendTheme } from '@chakra-ui/react';
import styles from './styles';
import Button from './components/button';
// import {
//   Code,
//   Divider,
//   Link,
//   ListItem,
//   OrderedList,
//   UnorderedList,
// } from '@chakra-ui/layout';
// import { Image } from '@chakra-ui/image';
// import { Heading, Text } from '@chakra-ui/react';
// import { Checkbox } from '@chakra-ui/checkbox';
// import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
// import { chakra } from '@chakra-ui/system';

const overrides = {
  colorScheme: 'brand',
  colors: {
    brand: {
      50: '#e4ebff',
      100: '#b5c2fd',
      200: '#859af9',
      300: '#5571f5',
      400: '#2a4af4',
      500: '#1730da',
      600: '#1026aa',
      700: '#091a79',
      800: '#02104a',
      900: '#00051c',
      primary: '#150E32',
      secondary: '#009DFF',
      textPrimary: 'black',
      textWhite: 'white',
    },
  },
  styles,
  components: {
    Button,
  },
};

export default extendTheme(overrides);

/**
 * These are for Markdown
 */
// const headingTheme = (props) => {
//   const { level, children } = props;
//   const sizes = ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'];
//   return (
//     <Heading
//       my={4}
//       as={`h${level}`}
//       size={sizes[`${level - 1}`]}
//       {...getCoreProps(props)}
//       color="brand.textWhite"
//     >
//       {children}
//     </Heading>
//   );
// };

// function getCoreProps(props) {
//   return props['data-sourcepos']
//     ? { 'data-sourcepos': props['data-sourcepos'] }
//     : {};
// }

// const newTheme = {
//   p: (props) => {
//     const { children } = props;
//     return (
//       <Text color="brand.textWhite" mb={2}>
//         {children}
//       </Text>
//     );
//   },
//   em: (props) => {
//     const { children } = props;
//     return (
//       <Text as="em" color="brand.textWhite">
//         {children}
//       </Text>
//     );
//   },
//   blockquote: (props) => {
//     const { children } = props;
//     return (
//       <Code as="blockquote" color="brand.textWhite" bgColor="gray" p={2}>
//         {children}
//       </Code>
//     );
//   },
//   code: (props) => {
//     const { inline, children, className } = props;

//     if (inline) {
//       return <Code p={2} color="brand.textWhite" children={children} />;
//     }

//     return (
//       <Code
//         className={className}
//         whiteSpace="break-spaces"
//         d="block"
//         w="full"
//         p={2}
//         children={children}
//       />
//     );
//   },
//   del: (props) => {
//     const { children } = props;
//     return <Text as="del">{children}</Text>;
//   },
//   hr: (props) => {
//     return <Divider />;
//   },
//   a: Link,
//   img: Image,
//   text: (props) => {
//     const { children } = props;
//     return <Text as="span">{children}</Text>;
//   },
//   ul: (props) => {
//     const { ordered, children, depth } = props;
//     const attrs = getCoreProps(props);
//     let Element = UnorderedList;
//     let styleType = 'disc';
//     if (ordered) {
//       Element = OrderedList;
//       styleType = 'decimal';
//     }
//     if (depth === 1) styleType = 'circle';
//     return (
//       <Element
//         spacing={2}
//         as={ordered ? 'ol' : 'ul'}
//         styleType={styleType}
//         pl={4}
//         {...attrs}
//       >
//         {children}
//       </Element>
//     );
//   },
//   ol: (props) => {
//     const { ordered, children, depth } = props;
//     const attrs = getCoreProps(props);
//     let Element = UnorderedList;
//     let styleType = 'disc';
//     if (ordered) {
//       Element = OrderedList;
//       styleType = 'decimal';
//     }
//     if (depth === 1) styleType = 'circle';
//     return (
//       <Element
//         spacing={2}
//         as={ordered ? 'ol' : 'ul'}
//         styleType={styleType}
//         pl={4}
//         {...attrs}
//       >
//         {children}
//       </Element>
//     );
//   },
//   li: (props) => {
//     const { children, checked } = props;
//     let checkbox = null;
//     if (checked !== null && checked !== undefined) {
//       checkbox = (
//         <Checkbox isChecked={checked} color="brand.textWhite" isReadOnly>
//           {children}
//         </Checkbox>
//       );
//     }
//     return (
//       <ListItem
//         {...getCoreProps(props)}
//         listStyleType={checked !== null ? 'none' : 'inherit'}
//         color="brand.textWhite"
//       >
//         {checkbox || children}
//       </ListItem>
//     );
//   },
//   h1: headingTheme,
//   h2: headingTheme,
//   h3: headingTheme,
//   h4: headingTheme,
//   h5: headingTheme,
//   h6: headingTheme,
//   pre: (props) => {
//     const { children } = props;
//     return (
//       <chakra.pre {...getCoreProps(props)} color="brand.textWhite">
//         {children}
//       </chakra.pre>
//     );
//   },
//   table: Table,
//   thead: Thead,
//   tbody: Tbody,
//   tr: (props) => <Tr>{props.children}</Tr>,
//   td: (props) => <Td>{props.children}</Td>,
//   th: (props) => <Th>{props.children}</Th>,
// };
