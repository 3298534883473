import React, { useEffect } from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination = ({
  currentPage,
  setCurrentPage,
  totalRecords = 0,
  pageLimit = 30,
  pageNeighbours = 1,
  onPageChanged = (f) => f,
}) => {
  const totalPages = Math.ceil(totalRecords / pageLimit);

  useEffect(() => {
    gotoPage(1);
  }, []);

  useEffect(() => {
    const totalPages = Math.ceil(totalRecords / pageLimit);

    const paginationData = {
      currentPage,
      totalPages,
      pageLimit,
      totalRecords,
    };
    onPageChanged(paginationData);
  }, [currentPage, totalRecords, pageLimit, onPageChanged]);

  const gotoPage = (page) => {
    const innerCurrentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage: innerCurrentPage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    setCurrentPage(innerCurrentPage);
    onPageChanged(paginationData);
  };

  const handleClick = (page) => (evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + 1);
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  if (!totalRecords || totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <HStack p={1} pb={3}>
      {pages.map((page, index) => {
        if (page === LEFT_PAGE)
          return (
            <Box key={index}>
              <Button
                size="sm"
                variant={currentPage === page ? 'primary' : 'secondary'}
                onClick={handleMoveLeft}
              >
                {/* <span aria-hidden="true">&laquo;</span> */}
                <span className="sr-only">Prev</span>
              </Button>
            </Box>
          );

        if (page === RIGHT_PAGE)
          return (
            <Box key={index}>
              <Button
                size="sm"
                variant={currentPage === page ? 'primary' : 'secondary'}
                onClick={handleMoveRight}
              >
                {/* <span aria-hidden="true">&raquo;</span> */}
                <span className="sr-only">Next</span>
              </Button>
            </Box>
          );

        return (
          <Box key={index}>
            <Button
              size="sm"
              variant={currentPage === page ? 'primary' : 'secondary'}
              onClick={handleClick(page)}
            >
              {page}
            </Button>
          </Box>
        );
      })}
    </HStack>
  );
};

export default Pagination;
