import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { useField, useFormikContext } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDateInput = React.forwardRef((props, ref) => (
  <Input ref={ref} {...props} placeholder="yyyy-mm-dd" maxLength={10} />
));

export default function DateField({ helperText, label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { submitCount } = useFormikContext();
  return (
    <FormControl isInvalid={meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel>{label}</FormLabel>
      <DatePicker
        {...field}
        {...props}
        dateFormat="yyyy/MM/dd"
        placeholder="yyyy-mm-dd"
        showMonthDropdown
        showYearDropdown
        customInput={<CustomDateInput />}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => helpers.setValue(val)}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
